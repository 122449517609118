import React, { useEffect, useState } from "react";
import "./blog.css";
import { useNavigate } from "react-router-dom";
import {collection, getDocs} from 'firebase/firestore';
import { useMediaQuery } from "react-responsive";
import {db} from './FirebaseConfig';

const BlogList = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [blogs, setBlogs] = useState([]); // Menggunakan useState untuk menyimpan data blog

  useEffect(() => {
    const fetchBlogs = async () => { // Membuat fungsi untuk mengambil data blog
      try {
        const coll = collection(db, "blogs"); // Mengakses koleksi 'blogs'
        const snapshot = await getDocs(coll); // Mengambil semua dokumen dalam koleksi
        const blogList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(), // Mengambil semua data dari setiap dokumen
        }));
        setBlogs(blogList); // Menyimpan data blog ke state
      } catch (error) {
        console.error("Error fetching blogs: ", error); // Menangani kesalahan
      }
    };

    fetchBlogs(); // Memanggil fungsi fetchBlogs
  }, []); 


  const handleClick = (id) => {
    navigate(`/blog-detail/${id}`);
    console.log(id)
  };


  if(isTabletOrMobile){
    return (
      <>
      <div className="blog">
        <h1>Blog</h1>
        <div>
          {blogs.map((post) => (
            <div key={post.id} className="blog-post" onClick={()=>handleClick(post.id)}>
              <img src={post.img_url} alt={post.img_url}/>
              <h2>{post.title}</h2>
              <p >{post.content}</p>
              <small>{post.date}</small>
            </div>
          ))}
        </div>
      </div>
       <footer className="footer">
       <ul>
         <a href="https://www.instagram.com/kodingyuk.id/">
           <i className="fab fa-instagram"></i>
         </a>
         <a href="https://www.facebook.com/profile.php?id=61565772189737">
           <i className="fab fa-facebook-f"></i>
         </a>
       </ul>
     </footer>
     </>
    );
  }else{
    
  }
  
}




export default BlogList;
